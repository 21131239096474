import { $, addAction, INIT } from '@situation/setdesign.util';
import 'select2';

addAction(INIT, () => {
    const apiRoot = $('#_wproot').attr('content');
    const $school = $('select[name="school_term_id"]');
    const requiredClass = 'validate[required]';
    $school.addClass(requiredClass);
    $school.select2({
        placeholder: 'Search for your school by name or number',
        ajax: { url: `${apiRoot}api/schools`, dataType: 'json' },
        language: {
            errorLoading() {
                return 'Searching...';
            },
        },
    });

    $('.school-field-toggle').on('click', (e) => {
        const $this = $(e.currentTarget);
        const $schoolFields = $('.school-field');
        $schoolFields.find('input').toggleClass(requiredClass).next('.formError').remove();
        $schoolFields.toggle();
        $school
            .attr('disabled', $this.is(':checked'))
            .toggleClass(requiredClass)
            .next('.formError')
            .remove()
            .val('')
            .trigger('change');
    });
});
