import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(
    INIT,
    () => {
        $('.um_user_form').data('validation-config', {
            isOverflown: true,
            overflownDIV: 'header.banner',
        });
    },
    9,
);

addAction(INIT, () => {
    $('a[href="#wp-toolbar"]').attr('tabindex', 100);
    $(document).on('gform_confirmation_loaded', (event, formId) => {
        if (formId === 1) {
            setTimeout(() => {
                if ($('section.contact-us').is(':visible')) {
                    $('section.contact-us').fadeOut();
                    $('body').removeClass('show-modal');
                }
            }, 5e3);
        }
    });

    function clickOffHandler(event) {
        const target = $(event.target);
        if (!(target.hasClass('.show-selector') || target.parents('.show-selector').length > 0)) {
            $('.show-selector .dropdown').slideUp();
            $(document).off('click', clickOffHandler);
        }
    }

    $('.show-selector').each((index, elem) => {
        const selector = $(elem);
        const cta = selector.children('.cta');
        const dropdown = selector.children('.dropdown');
        cta.on('click', () => {
            if (dropdown.is(':visible')) {
                $(document).off('click', clickOffHandler);
                dropdown.slideUp();
            } else {
                $(document).on('click', clickOffHandler);
                dropdown.slideDown();
            }
        });
    });

    $('.show-schedule .season .button.view-more').on('click', (e) => {
        const button = $(e.currentTarget);
        const target = button.attr('data-target');
        const list = $(`#${target}`);
        list.find('.show-event-list-item').fadeIn();
        button.fadeOut();
    });

    $('.menu-contact-us').click(window.toggleContactUs);
});
