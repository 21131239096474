import { addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    const cardListingTabs = document.querySelectorAll('.cards-listing__tab');
    if (!cardListingTabs.length) {
        return;
    }

    for (const index in cardListingTabs) {
        const tab = cardListingTabs[index];
        if (tab instanceof HTMLElement) {
            const TAB_ACTIVE_CLASS = 'cards-listing__tab--active';
            tab.addEventListener('click', (e) => {
                const target = e.currentTarget;
                if (!target.classList.contains(TAB_ACTIVE_CLASS)) {
                    for (const i in cardListingTabs) {
                        const t = cardListingTabs[i];
                        if (t instanceof HTMLElement) {
                            t.classList.remove(TAB_ACTIVE_CLASS);
                        }
                    }

                    target.classList.add(TAB_ACTIVE_CLASS);
                }

                const { seasonId } = target.dataset;
                const cards = document.querySelectorAll('.show-listing-card');
                if (seasonId.length) {
                    if (seasonId === 'all') {
                        const SHOW_LISTING_CARD_INACTIVE_CLASS = 'd-none';

                        for (const c in cards) {
                            const card = cards[c];
                            if (card instanceof HTMLElement) {
                                card.parentElement.classList.remove(
                                    SHOW_LISTING_CARD_INACTIVE_CLASS,
                                );
                            }
                        }
                    } else {
                        const SHOW_LISTING_CARD_INACTIVE_CLASS = 'd-none';

                        for (const c in cards) {
                            const card = cards[c];
                            if (card instanceof HTMLElement) {
                                card.parentElement.classList.add(SHOW_LISTING_CARD_INACTIVE_CLASS);
                            }
                        }

                        for (const d in cards) {
                            const card = cards[d];
                            if (card instanceof HTMLElement && card.dataset.seasonIds.length) {
                                const seasonIds = card.dataset.seasonIds.split(',');
                                if (seasonIds.includes(seasonId)) {
                                    card.parentElement.classList.remove(
                                        SHOW_LISTING_CARD_INACTIVE_CLASS,
                                    );
                                }
                            }
                        }
                    }
                }
            });
        }
    }
});
