import moment from 'moment';
import 'select2';
import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    const $showSelect = $('select.show-choice');
    const $showTimeSelect = $('select.show-time-choice');

    function formatShowTimes(data) {
        if (data.id === '') {
            return data.text;
        }
        return $(`<table>${data.rowHtml}</table>`);
    }

    function loadShowTimes(showID, selectTarget) {
        if (!showID) {
            $(selectTarget).select2({
                minimumResultsForSearch: Infinity,
                placeholder: 'Select Date',
            });
            return;
        }

        $(selectTarget).children().remove();
        $(selectTarget).val(null).trigger('change');
        $(selectTarget).select2({
            minimumResultsForSearch: Infinity,
            placeholder: 'Loading...',
            data: null,
        });

        const apiRoot = $('#_wproot').attr('content');
        $.ajax({ method: 'GET', url: `${apiRoot}api/show-events/${parseInt(showID, 10)}` })
            .done((data) => {
                const selectData = [{ id: '', text: 'Select Date' }];

                for (let i = 0, keys = Object.keys(data); i < keys.length; i += 1) {
                    const item = data[i];
                    const date = moment(item.date, 'MMMM D, YYYY h:MM a');
                    item.text = date.format('ddd MM/DD/YYYY');
                    selectData.push(item);
                }

                $(selectTarget).select2({
                    minimumResultsForSearch: Infinity,
                    placeholder: 'Select Date',
                    templateResult: formatShowTimes,
                    templateSelection: formatShowTimes,
                    data: selectData,
                });
            })
            .fail(() => {
                /* eslint-disable no-alert */
                alert('Unable to load show times. Please try again later.');
            })
            .always(() => {});
    }

    function validateTicketQuantities() {
        let invalid = false;
        const $error = $('.error.error--ticket-quantity');
        const $numTickets = $('#num_tickets');
        const $numStudents = $('#num_students');
        const $numChaperones = $('#num_chaperones');
        const ticketsCount = parseInt($numTickets.val(), 10);
        const studentsCount = parseInt($numStudents.val(), 10);
        const chaperonesCount = parseInt($numChaperones.val(), 10);
        const chaperonesRatio = parseInt($numChaperones.data('ratio'), 10);
        if (
            studentsCount + chaperonesCount !== ticketsCount
            || studentsCount / chaperonesCount > chaperonesRatio
        ) {
            invalid = true;
        }

        $showTimeSelect.each((index, elem) => {
            const select = $(elem);
            const availableTickets = select.data('available-tickets');
            if (availableTickets < ticketsCount) {
                invalid = true;
            }
        });

        if (invalid) {
            $error.show();
            $numTickets.focus();
        }
        return !invalid;
    }

    function clearErrors() {
        $('.error:not(.error--filled)').html('').hide();
    }

    function validateForm() {
        let invalid = false;
        clearErrors();
        $('form [required-field]').each((index, elem) => {
            const e = $(elem);
            let w = false;
            if (e.prop('tagName') === 'INPUT') {
                if (e.attr('type').toLowerCase() === 'checkbox') {
                    w = e.is(':checked') === false;
                } else {
                    w = e.val().trim().length === 0;
                }
            } else if (e.prop('tagName') === 'TEXTAREA') {
                w = e.val().trim().length === 0;
            } else if (e.prop('tagName') === 'SELECT') {
                w = !e.val()
                    || e.val().trim().length === 0
                    || e.find(`option[value="${e.val()}"]`).length === 0;
            }
            if (w) {
                e.closest('[class^="col-"]').find('.error').html('* This field is required').show();
                invalid = true;

                if (index === 0) {
                    e.focus();
                }
            }
        });

        if (!(invalid || validateTicketQuantities())) {
            invalid = true;
        }
        return !invalid;
    }

    function resetForm() {
        $('form input[type="text"], form input[type="number"], form textarea').val(null);
        $('form input[type="checkbox"]').prop('checked', false);
        $('select').val('').change();
        $('.show-time-choice option').remove();
    }

    clearErrors();
    $showSelect.on('change', (event) => {
        const select = $(event.currentTarget);
        const value = select.val();
        const target = $(`#${select.attr('data-target')}`);
        loadShowTimes(value, target);
    });

    $showSelect.select2({ minimumResultsForSearch: Infinity, placeholder: 'Select Show' });
    $showTimeSelect.select2({ minimumResultsForSearch: Infinity, placeholder: 'Select Date' });
    $showTimeSelect.on('select2:select', (event) => {
        const { data } = event.params;
        const select = $(event.currentTarget);
        select.data('available-tickets', data.availableTickets);
    });

    let isSubmitting = false;
    $('#request-tickets-form').on('submit', (event) => {
        const $this = $(event.currentTarget);
        event.preventDefault();

        if (validateForm() && !isSubmitting) {
            isSubmitting = true;
            $('.spinner').fadeIn();
            const data = $this.serializeArray().reduce((obj, item) => {
                const newObj = obj;
                newObj[item.name] = item.value;
                return newObj;
            }, {});
            const apiRoot = $('#_wproot').attr('content');
            $.ajax({
                method: 'POST',
                url: `${apiRoot}api/show-request/`,
                data,
                beforeSend(xhr) {
                    /* eslint-disable no-underscore-dangle */
                    xhr.setRequestHeader('X-WP-Nonce', data._wpnonce);
                },
            })
                .done(() => {
                    resetForm();
                    /* eslint-disable no-underscore-dangle */
                    window.location.href = data._wpredirect;
                })
                .fail(() => {
                    /* eslint-disable no-alert */
                    alert('An error has occurred. Please try again later.');
                })
                .always(() => {
                    $('.spinner').fadeOut();
                    isSubmitting = false;
                });
        }
        return false;
    });
});
