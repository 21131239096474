import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    const fields = $('.account-password').children().not('p');
    if (!fields.length) {
        return;
    }

    const left = $('<div class="col-sm-6">');
    left.append(fields);
    $('.account-password').prepend(left);
    $('.account-password > p').addClass('col-sm-6');
});
